.fullscreen {
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: inline-flex;
  position: absolute;
}

.homeGuide {
  float: left;
  top: 55px;
  position: relative;
  padding-left: 30px;
  font-size: 10px;
  color: #403f3f;
}

.otherGuide {
  float: right;
  top: 55px;
  position: relative;
  padding-right: 60px;
  right: 0px;
  font-size: 10px;
  color: #403f3f;
}
.image_404container {
  position: relative;
  width: calc(100% - 20px);
  margin: 10px;
  height: 80vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}

.image_404 {
  display: flex;
  max-width: 850px;
  align-self: center;
  position: relative;
  top: calc(5% + 10px);
  left: calc(0% + 0px);
  max-height: 600px;
  float: center;
}

.error404-footer {
  height: 50px;
  width: calc(100% - 20px);
  left: 0;
  top: calc(100% - 70px);
  padding: 10px;
  display: flex;
  position: absolute;
  float: bottom;
  background-color: #131015;
}

.link {
  display: inline-block;
  margin-bottom: 1px;
}

.error-link {
  font-size: 13px;
  color: #f5f5f5;
  line-height: 25px;
  float: left;
  text-decoration: none;
}

.error-link:hover {
  color: #cecdcd;
}

