.hero {
  height:100%;
  width: 100%;
  z-index: -1;
  background-color: #131015;
  justify-content: center;
  text-justify: center;
  align-items: center top;
  display: flex;
}

.log-container{
  width: auto;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}

.logs {
  color: #424242; 
  justify-content: center;
  text-justify: center;
  font-stretch: expanded;
  z-index: -1;
  font: Consolas;
}

.image-container {
  height: auto;
  width: 100%;
  z-index: 300;
  display: flex;
  align-items: flex-end;
}

.image {
  height: 50%;
  z-index: 500;
  position: absolute;
  bottom: 0;
  margin-right: 25px;
}

.typewriter {
  left: 0;
  font-weight: bold;
  color: #f58f67;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .1em; /* Adjust as needed */
  animation: 
    typing 1.25s steps(100, end),
    blink-caret 1000ms step-end infinite;
}

.typewriter-container {
  z-index: 500;
  position: absolute;
  top: 15vh;
  height: 120vh;
  font-size: 30vw;
  overflow: hidden;
}

.more {
  color: #ffb89c;
  left: 0;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}